// ==============================
//* SHOP THE LOOKS CONTAINER
//
//? This file add script for shop the look comportment.
// ==============================

// select cat

const changeCat = {
    selectors: {
        sliders: ".js-contain-cat-sliders > div",
        cats: ".js-cats > span",
        slider: ".slider",
        sliderWrapper: ".shopthelooks__content .slider-wrapper",
        container: ".shopthelooks__container",
        count: ".js-count-items"
    },
    class: {
        active: "active"
    },

    init() {
        const $sliders = $(this.selectors.sliders);
        const $cats = $(this.selectors.cats);

        $($sliders[0]).addClass(this.class.active);

        $sliders.each((i) => {
            let $items = $($($sliders[i]).find(this.selectors.container));

            $items.each((index) => {
                $($items[index]).find(this.selectors.count).text((index + 1) + "/" + $items.length);
            });
        });

        $($cats).on("click", (e) => {
            let $current = $(e.currentTarget);

            if (!$current.hasClass(this.class.active)) {
                let $slider = $($sliders[$current.data("value") - 1]);

                $cats.removeClass(this.class.active);
                $current.addClass(this.class.active);
                $sliders.removeClass(this.class.active);

                $sliders.find(".slider").slick("unslick");

                $slider.addClass(this.class.active).find(".slider").slick({
                    infinite: true,
                    cssEase: "linear",
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false,
                    autoplay: false,
                    autoplaySpeed: 5000,
                    pauseOnHover: true,
                    variableWidth: false,
                    centerMode: false,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                infinite: true,
                                cssEase: "linear",
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                arrows: true,
                                dots: false
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                infinite: true,
                                cssEase: "linear",
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                                dots: true
                            }
                        }
                    ]
                });
            }
        });
    }
};
changeCat.init();
